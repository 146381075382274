import Analytics from './analytics.js';
import { list } from './analytics.config.js';

export default class ListAnalytics extends Analytics {
  trackViewItemList() {
    this.listItemSlides = this.el.querySelectorAll(list.selectors.listItem);
    const event = {
      event: list.events.viewItemList,
      ecommerce: {
        items: [this.trackViewItemListData()],
      },
    };

    this.pushAnalytics(event);
  }

  trackViewItemListData() {
    const slides = [];
    this.listItemSlides.forEach((slide, index) => {
      const slideName = slide.querySelector(list.selectors.slideName) || slide.querySelector(list.selectors.slideShortName);
      const name = slideName ? slideName.innerText : '';
      const slidePrice = slide.querySelector(list.selectors.slidePrice);
      const price = slidePrice ? slidePrice.innerText : '';
      const slideId = slide.querySelector(list.selectors.slideId);
      const id = slideId ? slideId.innerText : '';

      const slideData = {
        item_name: name,
        item_id: id,
        price,
        item_brand: null,
        item_category: null,
        item_category_2: null,
        item_category_3: null,
        item_category_4: null,
        item_variant: null,
        item_list_name: document.title,
        item_list_id: null,
        index,
        quantity: '1',
      };
      slides.push(slideData);
    });
    return slides;
  }

  trackSelectItem(slide, index) {
    const slideName = slide.querySelector(list.selectors.slideName);
    const name = slideName ? slideName.innerText : '';
    const slidePrice = slide.querySelector(list.selectors.slidePrice);
    const price = slidePrice ? slidePrice.innerText : '';
    const slideId = slide.querySelector(list.selectors.slideId);
    const id = slideId ? slideId.innerText : '';

    const event = {
      event: list.events.selectItem,
      ecommerce: {
        items: [
          {
            item_name: name,
            item_id: id,
            item_brand: null, // we need some kind of filed in page product properties to get that
            item_category: null,
            item_category_2: null,
            item_category_3: null,
            item_category_4: null,
            item_variant: null,
            item_list_name: document.title,
            item_list_id: null,
            index,
            quantity: 1,
            price,
          },
        ],
      },
    };

    this.pushAnalytics(event);
  }
}
