export default {
  selectors: {
    listContainer: '.cmp-list__container',
    listTagsCmp: '.cmp-list__tags',
    listTag: '.cmp-list__tag',
    listItems: '.cmp-list__items-js',
    listItem: '.cmp-list__item-js',
    listItemWrapper: '.cmp-list__item-wrapper-js',
    listHiddenItems: '.cmp-list__item-js.batcom-hide',
    loadMoreButtonContainer: '.cmp-list__button-js',
    loadMoreButton: '.cmp-list__button-js > .cmp-button',
    dynamicItems: '.cmp-list__results--dynamicItems',
    results: '.cmp-list__resultsAll',
    carouselPrevButton: '.cmp-list__carousel-prev',
    carouselNextButton: '.cmp-list__carousel-next',
  },
  classes: {
    activeTag: 'cmp-list__tag--active',
    hidden: 'batcom-hide',
    isCarouselEnabled: 'cmp-list--carousel',
    isInfiniteScrollEnabled: 'cmp-infinite-scroll',
  },
  customProps: {},
};
