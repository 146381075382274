import { register } from '@netcentric/component-loader';
import ListAnalytics from 'commons/libs/analytics/analytics.list.js';
import config from './batcom-list.config';

class List {
  constructor(el) {
    this.el = el;
    this.selectors = config.selectors;
    this.classes = config.classes;
    this.init();
  }

  init() {
    this.setRefs();
    this.setEventListeners();
    this.addListAnalytics();
  }

  setRefs() {
    this.listItems = this.el.querySelector(this.selectors.listItems);
    if (this.listItems === null) {
      // eslint-disable-next-line no-console
      console.error('List component is not configured: no items to display!');
      return;
    }
    this.loadMoreButtonContainer = this.el.querySelector(this.selectors.loadMoreButtonContainer);
    this.loadMoreButton = this.el.querySelector(this.selectors.loadMoreButton);
    this.dynamicItems = this.el.querySelector(this.selectors.dynamicItems);
    this.results = this.el.querySelector(this.selectors.results);
    this.listTagsCmp = this.el.querySelectorAll(this.selectors.listTagsCmp);
    this.listTags = this.el.querySelectorAll(this.selectors.listTag);
    this.initialSize = Number(this.listItems.dataset.initialsize);
    this.itemsToShow = this.loadMoreButtonContainer && Number(this.loadMoreButtonContainer.dataset.pagesize);
    this.showTagFilter = this.listTagsCmp[0] && Boolean(this.listTagsCmp[0].dataset.showtagfilter);
    this.selectedTags = null;
    this.listItemSlides = this.el.querySelectorAll(this.selectors.listItem);
  }

  setEventListeners() {
    if (this.loadMoreButton) {
      this.loadMoreButton.addEventListener('click', this.onLoadMoreButtonClick.bind(this));
    }

    if (this.listTags && this.listTags.length > 1) {
      this.listTags.forEach(el => {
        el.addEventListener('click', this.onTagClick.bind(this));
      });
    }

    if (this.listItemSlides && this.listItemSlides.length > 0) {
      this.listItemSlides.forEach((slide, index) => {
        slide.addEventListener('click', () => {
            this.addItemClickAnalytics(slide, index);
        });
      });
    }
  }

  onTagClick(event) {
    event.preventDefault();
    const selectedEl = event.currentTarget;

    this.updateActiveTag(selectedEl);
    this.showItemsByTags(selectedEl);
  }

  updateActiveTag(selectedEl) {
    this.listTags.forEach(el => {
      el.classList.remove(this.classes.activeTag);
    });
    selectedEl.classList.add(this.classes.activeTag);
  }

  showItemsByTags(selectedEl) {
    const allTags = selectedEl.dataset.all;
    this.selectedTags = selectedEl.dataset.tag;

    if (allTags) {
      this.renderAllTagItems(Number(selectedEl.dataset.allitemscount));
    } else if (this.selectedTags) {
      const matchedItemsCount = Number(selectedEl.dataset.matcheditemscount);
      this.renderItemsByTags(this.selectedTags, matchedItemsCount);
    }
  }

  renderAllTagItems(allItemsCount) {
    const items = this.el.querySelectorAll(this.selectors.listItemWrapper);
    let dynamicItems = 0;

    items.forEach((el, index) => {
      const parentEl = el.closest(this.selectors.listItem);
      if (index < this.initialSize) {
        parentEl.classList.remove(this.classes.hidden);
        dynamicItems += 1;
      } else parentEl.classList.add(this.classes.hidden);
    });
    // update results
    if (this.results && this.dynamicItems) {
      this.updateTagResults(allItemsCount, dynamicItems);
    }
  }

  renderItemsByTags(selectedTags, matchedItemsCount) {
    const items = this.el.querySelectorAll(this.selectors.listItemWrapper);
    let matchedItems = 0;
    let dynamicItems = 0;

    items.forEach(el => {
      const parentEl = el.closest(this.selectors.listItem);

      // show only articles which match selectedTag
      if (el.dataset.tags.includes(selectedTags)) {
        matchedItems += 1;
        if (parentEl.classList.contains(this.classes.hidden) && matchedItems <= this.initialSize) {
          parentEl.classList.remove(this.classes.hidden);
        }
        if (!parentEl.classList.contains(this.classes.hidden)) {
          dynamicItems += 1;
        }
      } else if (!parentEl.classList.contains(this.classes.hidden)) {
        parentEl.classList.add(this.classes.hidden);
      }
    });

    // update results
    if (this.results && this.dynamicItems) {
      this.updateTagResults(matchedItemsCount, dynamicItems);
    }
  }

  updateTagResults(matchedItemsCount, dynamicItems) {
    this.results.innerText = matchedItemsCount;
    this.dynamicItems.innerText = dynamicItems;

    if (dynamicItems === matchedItemsCount) {
      this.loadMoreButtonContainer.classList.add(this.classes.hidden);
    } else {
      this.loadMoreButtonContainer.classList.remove(this.classes.hidden);
    }
  }

  onLoadMoreButtonClick(event) {
    event.preventDefault();
    this.showMoreItems();
  }

  showMoreItems() {
    let dynamicItems = Number(this.dynamicItems.innerText);
    const allHiddenListItems = this.el.querySelectorAll(this.selectors.listHiddenItems);
    let matchedTaggedItems = 0;

    if (allHiddenListItems.length) {
      allHiddenListItems.forEach((el, index) => {
        if (this.showTagFilter && this.selectedTags) {
          const tagItem = el.querySelector(this.selectors.listItemWrapper);

          if (tagItem.dataset.tags.includes(this.selectedTags)) {
            if (el.classList.contains(this.classes.hidden) && matchedTaggedItems < this.itemsToShow) {
              el.classList.remove(this.classes.hidden);
              dynamicItems += 1;
              matchedTaggedItems += 1;
            }
          }
        } else if (index < this.itemsToShow) {
          el.classList.remove(this.classes.hidden);
          dynamicItems += 1;
        }
      });
    }

    // Update results
    this.dynamicItems.innerText = dynamicItems;
    const allResultsCount = Number(this.results.innerText);

    if (dynamicItems === allResultsCount) {
      this.loadMoreButtonContainer.classList.add(this.classes.hidden);
    }
  }

  addListAnalytics() {
    this.listAnalytics = new ListAnalytics(this.el).trackViewItemList();
  }

  addItemClickAnalytics(slide, index) {
    this.itemListAnaltyics = new ListAnalytics(this.el).trackSelectItem(slide, index);
  }
}

register({ List });
